<template>
  <div class="login">
    <div class="logo-container">
      <Logo24G class="main-logo" />
      <DigitalPassport class="digital-logo" />
      <span class="dashboard-text">Dashboard</span>
    </div>
    <LoginForm class="form" ref="form" />
  </div>
</template>

<script>
import LoginForm from '@/components/LoginForm.vue';
import Logo24G from '@/assets/images/logo_24g.svg';
import DigitalPassport from '@/assets/images/logo_digital_passport.svg';

export default {
  name: 'login',
  components: {
    LoginForm,
    Logo24G,
    DigitalPassport,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
  overflow-y: auto !important;
  color: white;
  background-image: url(../assets/images/bg_admin_login@2x.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center top;
  background-size: cover;
  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .main-logo {
    width: 3.875em;
    height: 1.875em;
  }
  .digital-logo {
    width: 14.5em;
    margin-top: 20px;
    margin-bottom: 1em;
  }
  .dashboard-text {
    margin-bottom: 2em;
    font-weight: bold;
    text-transform: uppercase;
  }
  h3 {
    margin: 0;
    margin-top: -20px;
    margin-left: -10px;
    color: white;
  }
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 290px;
    padding-bottom: 100px;
    margin-top: 50px;
    input {
      width: 100%;
    }
    > :not(:first-child) {
      margin-top: 15px;
    }
  }
}
</style>
